.projects-component-section {
    padding: 10px !important;
    margin: 0 !important;
}

.projects-component-section .photo-section {
    height: 250px;
}

.projects-component-section .photo-section img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}