:root {
    /* Colors from https://mui.com/material-ui/customization/color/ */
    --red-500: #f44336;
    --red-50: #ffebee;
    --red-100: #ffcdd2;
    --red-200: #ef9a9a;
    --red-300: #e57373;
    --red-400: #ef5350;
    --red-600: #e53935;
    --red-700: #d32f2f;
    --red-800: #c62828;
    --red-900: #b71c1c;
    --red-A100: #ff8a80;
    --red-A200: #ff5252;
    --red-A400: #ff1744;
    --red-A700: #d50000;
    --pink-500: #e91e63;
    --pink-50: #fce4ec;
    --pink-100: #f8bbd0;
    --pink-200: #f48fb1;
    --pink-300: #f06292;
    --pink-400: #ec407a;
    --pink-600: #d81b60;
    --pink-700: #c2185b;
    --pink-800: #ad1457;
    --pink-900: #880e4f;
    --pink-A100: #ff80ab;
    --pink-A200: #ff4081;
    --pink-A400: #f50057;
    --pink-A700: #c51162;
    --purple-500: #9c27b0;
    --purple-50: #f3e5f5;
    --purple-100: #e1bee7;
    --purple-200: #ce93d8;
    --purple-300: #ba68c8;
    --purple-400: #ab47bc;
    --purple-600: #8e24aa;
    --purple-700: #7b1fa2;
    --purple-800: #6a1b9a;
    --purple-900: #4a148c;
    --purple-A100: #ea80fc;
    --purple-A200: #e040fb;
    --purple-A400: #d500f9;
    --purple-A700: #aa00ff;
    --deepPurple-500: #673ab7;
    --deepPurple-50: #ede7f6;
    --deepPurple-100: #d1c4e9;
    --deepPurple-200: #b39ddb;
    --deepPurple-300: #9575cd;
    --deepPurple-400: #7e57c2;
    --deepPurple-600: #5e35b1;
    --deepPurple-700: #512da8;
    --deepPurple-800: #4527a0;
    --deepPurple-900: #311b92;
    --deepPurple-A100: #b388ff;
    --deepPurple-A200: #7c4dff;
    --deepPurple-A400: #651fff;
    --deepPurple-A700: #6200ea;
    --indigo-500: #3f51b5;
    --indigo-50: #e8eaf6;
    --indigo-100: #c5cae9;
    --indigo-200: #9fa8da;
    --indigo-300: #7986cb;
    --indigo-400: #5c6bc0;
    --indigo-600: #3949ab;
    --indigo-700: #303f9f;
    --indigo-800: #283593;
    --indigo-900: #1a237e;
    --indigo-A100: #8c9eff;
    --indigo-A200: #536dfe;
    --indigo-A400: #3d5afe;
    --indigo-A700: #304ffe;
    --blue-500: #2196f3;
    --blue-50: #e3f2fd;
    --blue-100: #bbdefb;
    --blue-200: #90caf9;
    --blue-300: #64b5f6;
    --blue-400: #42a5f5;
    --blue-600: #1e88e5;
    --blue-700: #1976d2;
    --blue-800: #1565c0;
    --blue-900: #0d47a1;
    --blue-A100: #82b1ff;
    --blue-A200: #448aff;
    --blue-A400: #2979ff;
    --blue-A700: #2962ff;
    --lightBlue-500: #03a9f4;
    --lightBlue-50: #e1f5fe;
    --lightBlue-100: #b3e5fc;
    --lightBlue-200: #81d4fa;
    --lightBlue-300: #4fc3f7;
    --lightBlue-400: #29b6f6;
    --lightBlue-600: #039be5;
    --lightBlue-700: #0288d1;
    --lightBlue-800: #0277bd;
    --lightBlue-900: #01579b;
    --lightBlue-A100: #80d8ff;
    --lightBlue-A200: #40c4ff;
    --lightBlue-A400: #00b0ff;
    --lightBlue-A700: #0091ea;
    --cyan-500: #00bcd4;
    --cyan-50: #e0f7fa;
    --cyan-100: #b2ebf2;
    --cyan-200: #80deea;
    --cyan-300: #4dd0e1;
    --cyan-400: #26c6da;
    --cyan-600: #00acc1;
    --cyan-700: #0097a7;
    --cyan-800: #00838f;
    --cyan-900: #006064;
    --cyan-A100: #84ffff;
    --cyan-A200: #18ffff;
    --cyan-A400: #00e5ff;
    --cyan-A700: #00b8d4;
    --teal-500: #009688;
    --teal-50: #e0f2f1;
    --teal-100: #b2dfdb;
    --teal-200: #80cbc4;
    --teal-300: #4db6ac;
    --teal-400: #26a69a;
    --teal-600: #00897b;
    --teal-700: #00796b;
    --teal-800: #00695c;
    --teal-900: #004d40;
    --teal-A100: #a7ffeb;
    --teal-A200: #64ffda;
    --teal-A400: #1de9b6;
    --teal-A700: #00bfa5;
    --green-500: #4caf50;
    --green-50: #e8f5e9;
    --green-100: #c8e6c9;
    --green-200: #a5d6a7;
    --green-300: #81c784;
    --green-400: #66bb6a;
    --green-600: #43a047;
    --green-700: #388e3c;
    --green-800: #2e7d32;
    --green-900: #1b5e20;
    --green-A100: #b9f6ca;
    --green-A200: #69f0ae;
    --green-A400: #00e676;
    --green-A700: #00c853;
    --lightGreen-500: #8bc34a;
    --lightGreen-50: #f1f8e9;
    --lightGreen-100: #dcedc8;
    --lightGreen-200: #c5e1a5;
    --lightGreen-300: #aed581;
    --lightGreen-400: #9ccc65;
    --lightGreen-600: #7cb342;
    --lightGreen-700: #689f38;
    --lightGreen-800: #558b2f;
    --lightGreen-900: #33691e;
    --lightGreen-A100: #ccff90;
    --lightGreen-A200: #b2ff59;
    --lightGreen-A400: #76ff03;
    --lightGreen-A700: #64dd17;
    --lime-500: #cddc39;
    --lime-50: #f9fbe7;
    --lime-100: #f0f4c3;
    --lime-200: #e6ee9c;
    --lime-300: #dce775;
    --lime-400: #d4e157;
    --lime-600: #c0ca33;
    --lime-700: #afb42b;
    --lime-800: #9e9d24;
    --lime-900: #827717;
    --lime-A100: #f4ff81;
    --lime-A200: #eeff41;
    --lime-A400: #c6ff00;
    --lime-A700: #aeea00;
    --yellow-500: #ffeb3b;
    --yellow-50: #fffde7;
    --yellow-100: #fff9c4;
    --yellow-200: #fff59d;
    --yellow-300: #fff176;
    --yellow-400: #ffee58;
    --yellow-600: #fdd835;
    --yellow-700: #fbc02d;
    --yellow-800: #f9a825;
    --yellow-900: #f57f17;
    --yellow-A100: #ffff8d;
    --yellow-A200: #ffff00;
    --yellow-A400: #ffea00;
    --yellow-A700: #ffd600;
    --amber-500: #ffc107;
    --amber-50: #fff8e1;
    --amber-100: #ffecb3;
    --amber-200: #ffe082;
    --amber-300: #ffd54f;
    --amber-400: #ffca28;
    --amber-600: #ffb300;
    --amber-700: #ffa000;
    --amber-800: #ff8f00;
    --amber-900: #ff6f00;
    --amber-A100: #ffe57f;
    --amber-A200: #ffd740;
    --amber-A400: #ffc400;
    --amber-A700: #ffab00;
    --orange-500: #ff9800;
    --orange-50: #fff3e0;
    --orange-100: #ffe0b2;
    --orange-200: #ffcc80;
    --orange-300: #ffb74d;
    --orange-400: #ffa726;
    --orange-600: #fb8c00;
    --orange-700: #f57c00;
    --orange-800: #ef6c00;
    --orange-900: #e65100;
    --orange-A100: #ffd180;
    --orange-A200: #ffab40;
    --orange-A400: #ff9100;
    --orange-A700: #ff6d00;
    --deepOrange-500: #ff5722;
    --deepOrange-50: #fbe9e7;
    --deepOrange-100: #ffccbc;
    --deepOrange-200: #ffab91;
    --deepOrange-300: #ff8a65;
    --deepOrange-400: #ff7043;
    --deepOrange-600: #f4511e;
    --deepOrange-700: #e64a19;
    --deepOrange-800: #d84315;
    --deepOrange-900: #bf360c;
    --deepOrange-A100: #ff9e80;
    --deepOrange-A200: #ff6e40;
    --deepOrange-A400: #ff3d00;
    --deepOrange-A700: #dd2c00;
    --brown-500: #795548;
    --brown-50: #efebe9;
    --brown-100: #d7ccc8;
    --brown-200: #bcaaa4;
    --brown-300: #a1887f;
    --brown-400: #8d6e63;
    --brown-600: #6d4c41;
    --brown-700: #5d4037;
    --brown-800: #4e342e;
    --brown-900: #3e2723;
    --brown-A100: #d7ccc8;
    --brown-A200: #bcaaa4;
    --brown-A400: #8d6e63;
    --brown-A700: #5d4037;
    --grey-500: #9e9e9e;
    --grey-50: #fafafa;
    --grey-100: #f5f5f5;
    --grey-200: #eeeeee;
    --grey-300: #e0e0e0;
    --grey-400: #bdbdbd;
    --grey-600: #757575;
    --grey-700: #616161;
    --grey-800: #424242;
    --grey-900: #212121;
    --grey-A100: #f5f5f5;
    --grey-A200: #eeeeee;
    --grey-A400: #bdbdbd;
    --grey-A700: #616161;
    --blueGrey-500: #607d8b;
    --blueGrey-50: #eceff1;
    --blueGrey-100: #cfd8dc;
    --blueGrey-200: #b0bec5;
    --blueGrey-300: #90a4ae;
    --blueGrey-400: #78909c;
    --blueGrey-600: #546e7a;
    --blueGrey-700: #455a64;
    --blueGrey-800: #37474f;
    --blueGrey-900: #263238;
    --blueGrey-A100: #cfd8dc;
    --blueGrey-A200: #b0bec5;
    --blueGrey-A400: #78909c;
    --blueGrey-A700: #455a64;

    --text-shadow-1: 1px 1px 3px black;
    --box-shadow-1: 2px 2px rgb(100, 100, 100);
}

.blue-1 {
    background-color: var(--blue-1);
}
.blue-1:hover {
    background-color: var(--blue-2);
}


.blue-2 {
    background-color: var(--blue-2);
}
.blue-2:hover {
    background-color: var(--blue-3);
}


.blue-3 {
    background-color: var(--blue-3);
}
.blue-3:hover {
    background-color: var(--blue-4);
}


.blue-4 {
    background-color: var(--blue-4);
}
.blue-4:hover {
    background-color: var(--blue-5);
}


.blue-5 {
    background-color: var(--blue-5);
}



.red-1 {
    background-color: var(--red-1);
}
.red-1:hover {
    background-color: var(--red-2);
}


.red-2 {
    background-color: var(--red-2);
}
.red-2:hover {
    background-color: var(--red-3);
}


.red-3 {
    background-color: var(--red-3);
}
.red-3:hover {
    background-color: var(--red-4);
}


.red-4 {
    background-color: var(--red-4);
}
.red-4:hover {
    background-color: var(--red-5);
}


.red-5 {
    background-color: var(--red-5);
}



.green-1 {
    background-color: var(--green-1);
}
.green-1:hover {
    background-color: var(--green-2);
}


.green-2 {
    background-color: var(--green-2);
}
.green-2:hover {
    background-color: var(--green-3);
}


.green-3 {
    background-color: var(--green-3);
}
.green-3:hover {
    background-color: var(--green-4);
}


.green-4 {
    background-color: var(--green-4);
}
.green-4:hover {
    background-color: var(--green-5);
}


.green-5 {
    background-color: var(--green-5);
}



.purple-1 {
    background-color: var(--purple-1);
}
.purple-1:hover {
    background-color: var(--purple-2);
}


.purple-2 {
    background-color: var(--purple-2);
}
.purple-2:hover {
    background-color: var(--purple-3);
}


.purple-3 {
    background-color: var(--purple-3);
}
.purple-3:hover {
    background-color: var(--purple-4);
}


.purple-4 {
    background-color: var(--purple-4);
}
.purple-4:hover {
    background-color: var(--purple-5);
}


.purple-5 {
    background-color: var(--purple-5);
}


.grey-1 {
    background-color: var(--grey-1);
}
.grey-1:hover {
    background-color: var(--grey-2);
}


.grey-2 {
    background-color: var(--grey-2);
}
.grey-2:hover {
    background-color: var(--grey-3);
}


.grey-3 {
    background-color: var(--grey-3);
}
.grey-3:hover {
    background-color: var(--grey-4);
}


.grey-4 {
    background-color: var(--grey-4);
}
.grey-4:hover {
    background-color: var(--grey-5);
}


.grey-5 {
    background-color: var(--grey-5);
}


.orange-1 {
    background-color: var(--orange-1);
}
.orange-1:hover {
    background-color: var(--orange-2);
}


.orange-2 {
    background-color: var(--orange-2);
}
.orange-2:hover {
    background-color: var(--orange-3);
}


.orange-3 {
    background-color: var(--orange-3);
}
.orange-3:hover {
    background-color: var(--orange-4);
}


.orange-4 {
    background-color: var(--orange-4);
}
.orange-4:hover {
    background-color: var(--orange-5);
}


.orange-5 {
    background-color: var(--orange-5);
}

.section-colors {
    display: flex;
    flex-direction: column;
    
    position: absolute;
    left: 100px;
    top: 870px;

    z-index: 100;
}

.section-colors > section > button {
    border: 0;
    color: white;
    text-shadow: var(--shadow-1);
    width: 70px;
}